// Entry point for the build script in your package.json
import * as bootstrap from 'bootstrap';
import '@hotwired/turbo-rails';
import '../controllers'
import { Datepicker } from 'vanillajs-datepicker';
import datepickerPtBR from 'vanillajs-datepicker/locales/pt-BR';

Object.assign(Datepicker.locales, datepickerPtBR);

// DOMContentLoaded event listener is triggered
// when the initial HTML document has been completely loaded and parsed
//
// When using Turbo is preferable to use the turbo:load event
document.addEventListener('DOMContentLoaded', () => {
  (() => {
    // --------
    // Popovers
    // --------
    // Instantiate all popovers in a docs or StackBlitz page
    // document.querySelectorAll('[data-bs-toggle="popover"]')
    //   .forEach(popover => {
    //   new bootstrap.Popover(popover)
    // })
  })();
});

// As we using Turbo, we must use the turbo:load event
document.addEventListener('turbo:load', () => {
  (() => {
    document.querySelectorAll('.datepicker-bs5').forEach((element) => {
      // Console log to check if the datepicker is being initialized
      console.log('Initialized datepicker on element: ', element);
      console.log(Datepicker.locales);
      new Datepicker(element, {
        format: 'dd/mm/yyyy',
        buttonClass: 'btn',
        language: 'pt-BR',
        autohide: true,
      });

      // Add an event listener to trigger the input event when the date changes
      element.addEventListener('changeDate', (event) => {
        const inputEvent = new Event('input', { bubbles: true });
        event.target.dispatchEvent(inputEvent);
      });
    });

    document
      .querySelectorAll('a[data-export-file]')
      .forEach((element) => {
        element.addEventListener('click', function (e) {
          e.preventDefault();
          const form_selector = this.closest('form');
          const type = this.getAttribute('data-export-file');
          submit_form_to_export(form_selector, type);
        });
      });

    window.addEventListener('scroll', (e) => {
      e.preventDefault();
      const btnContainer = document.querySelector('.btn-container');
      if (btnContainer) {
        if (window.scrollY + window.innerHeight >= document.body.scrollHeight - 100) {
          btnContainer.style.display = 'none';
        } else {
          btnContainer.style.display = 'block';
        }
      }
    });

    function submit_form_to_export(form_selector, type) {
      const form = form_selector;
      const hidden = document.createElement('input');
      hidden.type = 'hidden';
      hidden.name = 'export-file';
      hidden.value = type;
      form.appendChild(hidden);

      // Tornando o formulário não remoto (POST)
      form.removeAttribute('data-remote');

      form.submit();
      form.setAttribute('data-remote', true);
      hidden.remove();
    }
  })();
});
